// import craters from "./assets/craters.json"
import proj4  from "proj4"


const ProjectionMoonLongLat = `+proj=longlat +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +a=1737400 +b=1737400 +units=m`
const ProjectionMoonWebMercator = `+proj=merc +a=1737400 +b=1737400 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs`

const ProjectionMoonPoleNorth = `+proj=stere +lat_0=90 +lon_0=0 +k=1 +x_0=0 +y_0=0 +a=1737400 +b=1737400 +units=m`
const ProjectionMoonPoleSouth = `+proj=stere +lat_0=-90 +lon_0=0 +k=1 +x_0=0 +y_0=0 +a=1737400 +b=1737400 +units=m`


async function List(x0: number, y0: number, x1: number, y1: number, mapName: string, zoom: number = 50) {
    let ret: any = []

    let kx:string = `${mapName}_x`
    let ky:string = `${mapName}_y`

    let craters = (await import("./assets/craters.json")).default

    // 在不同层级的要显示的环形山的最小直径（经验值）
    let minDiameter: number = 330;
    if (zoom >= 2) { minDiameter = 300; }
    if (zoom >= 3) { minDiameter = 200; }
    if (zoom >= 4) { minDiameter = 150; }
    if (zoom >= 5) { minDiameter = 90; }
    if (zoom >= 6) { minDiameter = 10; }
    if (zoom >= 7) { minDiameter = 0; }
    

    craters.forEach(v => {
        // if (ret.length >= limit) {
        //     return
        // }
        if (v.diameter_km < minDiameter) {
            return
        }
        if (x0 < v[kx]  && v[kx] < x1) {
            if (y0 < v[ky] && v[ky] < y1) {
                ret.push(Object.assign({}, v))   
            }
        }
    })
    
    // console.log(`查询环形山数据(${mapName}, ${zoom})，得到 ${ret.length} 个结果，范围是`, x0, y0, x1, y1)

    return ret
}

// 获取指定投影平面上一个范围内的环形山数据
export async function GetCraters(latitude: number, longitude: number, zoom: number, mapName: string) {
    let toProjection: string = ''

    // 1. 选择投影坐标系
    if (mapName == 'lowlat') {
        toProjection = ProjectionMoonWebMercator
    } else if (mapName == 'north') {
        toProjection = ProjectionMoonPoleNorth
    } else if (mapName == 'south') {
        toProjection = ProjectionMoonPoleSouth
    } else {
        throw `mapName '${mapName}'参数不正确`
    }

    // 2. 将经纬度转换成投影坐标
    let p = proj4(toProjection)
    let x: number
    let y: number

    [x, y] = p.forward([longitude, latitude])
    // console.log(`转换结果：纬度和经度 (${latitude}, ${longitude}) --> (${x}, ${y})`)

    // 3. 计算范围
    // 我们认为，在 3z 层级，正好可以放下整个地图，依次计算应该查询的位置范围
    // 在最大层级上，我们正好在垂直方向上可以看到整个地图（经验值），于是可见范围即月球周长，center 的左右偏移即为半周长
	// 在下一个层级上，可见范围与左右偏移正好减半
	// 在水平方向上，考虑到宽屏屏幕，我们将水平覆盖距离翻倍
    const FullViewZ:number = 3
    const MoonR: number = 1737400
    const MoonL: number = Math.round(MoonR*Math.PI*2) - 1
    let offset: number 

    if (mapName == "lowlat") {
        // 对于中低纬，限制查询范围半周长
        if (zoom > FullViewZ) {
            offset = MoonL / 2 / Math.pow(2, zoom - FullViewZ)
        } else {
            offset = MoonL / 2
        }
    } else {
        if (zoom > FullViewZ) {
            offset = (MoonR * 2) / Math.pow(2, zoom - FullViewZ)
        } else {
            offset = MoonR * 2
        }
    }

    let x0: number = x - offset*2   // 水平覆盖范围翻倍
    let x1: number = x + offset*2
    let y0: number = y - offset
    let y1: number = y + offset

    // 避免水平坐标超出范围
    if (x0 < -MoonL / 2 + 2) {
        x0 = -MoonL / 2 + 2
    }
    if (x1 > MoonL / 2 - 2) {
        x1 = MoonL / 2 - 2
    }


    // 4. 额外计算当前矩形区域范围并返回
    let p0From = [x0, y0]
    let p1From = [x0, y1]
    let p2From = [x1, y1]
    let p3From = [x1, y0]
    let p0 = p.inverse(p0From)
    let p1 = p.inverse(p1From)
    let p2 = p.inverse(p2From)
    let p3 = p.inverse(p3From)

    // 对于不同的放大级别，我们返回不同数量的环形山
    let limit: number = 10
    if (zoom >= 2) { limit = 20; }
    if (zoom >= 3) { limit = 30; }
    if (zoom >= 4) { limit = 50; }
    if (zoom >= 5) { limit = 70; }
    if (zoom >= 6) { limit = 100; }
    if (zoom >= 7) { limit = 150; }
    


    let craters = await List(x0, y0, x1, y1, mapName, zoom)

    // console.log("查询到的环形山数据是", craters)
    // console.log(`当前中心点是 (${x}, ${y})，范围是 (${x0}, ${y0})~(${x1}, ${y1})`)
    // console.log(`P0 点坐标为：${p0From} -> ${p0}`)
    // console.log(`P1 点坐标为：${p1}`)
    // console.log(`P2 点坐标为：${p2}`)
    // console.log(`P3 点坐标为：${p3}`)
    

    return {craters:  craters, bounds: [p0, p1, p2, p3]}
}