export const cache = {
    prefix: "cache_",

    // 设置一个缓存。如果不提供 ttl，则永不过期
    // ttl 为秒
    set(name: string, data: any, ttl?: number) {
        // console.debug(`设置缓存 ${name}，超时时间为 ${ttl} 秒`)

        let obj = {
            ttl,
            data,
            expireTime: (new Date()).getTime() / 1000 + (ttl ?? 0)
        }
        localStorage.setItem(`${this.prefix}${name}`, JSON.stringify(obj))
    },

    // 获取一个缓存。如果缓存不存在或已过期则返回 undefined
    getRaw(name: string) {
        let raw: string|null = localStorage.getItem(`${this.prefix}${name}`)
        if (raw == null) {
            return undefined
        }

        let obj = JSON.parse(raw)
        let ts = (new Date()).getTime()
        if (obj.ttl !== undefined && ts / 1000 > obj.expireTime) {
            console.log(`本地缓存 ${name} 已经过期，删除之`)
            localStorage.removeItem(`${this.prefix}${name}`)
            return undefined
        }

        return obj
    },

    get(name: string) {
        let d = this.getRaw(name)
        if (d !== undefined){ 
            return d.data
        } else {
            return undefined
        }
    },

    // 获取并刷新一个缓存的过期时间。如果缓存不存在或已过期则返回 undefined
    touch(name: string) {
        let d = this.getRaw(name)
        if (d !== undefined) {
            d.expireTime = (new Date()).getTime() / 1000 + d.ttl
            localStorage.setItem(`${this.prefix}${name}`, JSON.stringify(d))
        }
        return d.data
    },

    remove(name: string) {
        localStorage.removeItem(`${this.prefix}${name}`)
    },
}
    

