import {SVGText, MeasureText, RocketURL, CommentURL} from "./svgtext.js"


// 设置 API 地址
export var API_BASE_FZ = "http://ss.ts.bbxy.net:5543"
                                API_BASE_FZ = "https://ss-api-ce2.greensea.org" // DEBUG ONLY
if (import.meta.env.PROD == true) {
    API_BASE_FZ = "https://fz-api-ce2.greensea.org"
}
export var API_BASE_SS = "http://ss.ts.bbxy.net:5543"
if (import.meta.env.PROD == true) {
    API_BASE_SS = "https://ss-api-ce2.greensea.org"
}
export var API_BASE_KK = "http://kk.ts.bbxy.net:5543"
if (import.meta.env.PROD == true) {
    API_BASE_KK = "https://kk-api-ce2.greensea.org"
}


// 基本字体大小，对应 10km 级别的物体
// 物体直径扩大 10 倍，则字体大小扩大 2 倍，即 字体大小=Log(直径千米)*BaseFontSize
const BaseFontSize = 14

const Colors = {
    "环形山": "#fff",
    "山脉": "#ffd9b3",
    "山脊": "#cc0",
    "链坑": "#fff",
    "断崖": "#c30",
    "直壁": "#c30",  // 直壁是一个断崖的名字
    "洋": "#66d",
    "海": "#00f",
    "溪": "#75d", 
    "沼": "#669",
    "湾": "#69f",
    "山": "#ff8",
    "岬": "#099",
}

// 定义各个层级的可以显示的最小的物体尺寸
export const MinDiameterOfZooms = {
    1: 1000,    // 在第一层可以显示直径大于 1000km 的物体
    2: 600,     // 第二层可以显示直径大于 500km 的物体
    3: 350,
    4: 170,
    5: 90,
    // 6: 32,
    // 其他层级无要求
}





// 创建一个地点的 Marker
export const MakeMarker = (item) => {
    let size = BaseFontSize
    let color = "#093"  // #093 = 浅墨绿

    // 计算大小
    let diameter = item.position ?? 10;
    diameter = parseFloat(diameter)
    if (diameter < 10) {
        diameter = 10
    }
    size = Math.round(Math.log10(diameter) * BaseFontSize)
    if (isNaN(size)) {
        size = BaseFontSize
    }
    size = Math.ceil(size) + "px"

    // 计算颜色
    for (let k in Colors) {
        let name= item.name ?? ""
        let klen = k.length
        if (name.slice(-klen) == k) {
            color = Colors[k]
            break;
        }
    }


    let name = item.name ?? "（缺少名字？）"
    if (item.type == "artifical") {
        name = "🙭"
        color = "#6cf"
        size = BaseFontSize
    }

    if (item.type == "crater" ){
        // 字体范围为 0.8 ~ 1.5 倍
        // 对应环形山范围为 小于 50 ～ 大于 300
        let a =(0.7 / 250)
        let b = (0.8 - 35 / 250)
        let ratio = item.diameter_km * a + b
        ratio = Math.max(Math.min(ratio, 1.5), 0.8)
        size = BaseFontSize * ratio
    }

    let measure = MeasureText(name, `bold ${size} sans`)

    let iconURL = SVGText(name, {size: size, color: color})
    if (item.type == "artifical") {
        iconURL = RocketURL
    }

    let m = new L.marker(new L.LatLng(item.latitude, item.longitude), {
        icon: new L.Icon({
             iconUrl: iconURL,
             iconAnchor: [measure.width / 2, measure.height / 2],
        }),
    })

    // 拼接弹框信息
    let desc = ""
    if (item.type == "crater") {
        let position = FormatPosition(item.latitude, item.longitude)
        desc += `<div><span>位置:</span> ${position}</div>`
    } else {
        desc += `<div><span>位置:</span> ${item.position}</div>`
    }

    if (item.type == "mountain" || item.type == "water" || item.type == "crater") {
        // 山水的信息
        if (item.diameter_km) {
            desc += `<div><span>范围:</span> ${item.diameter_km} 千米</div>`
        }
        if (item.name_en) {
            desc += `<div><span>拉丁名:</span> ${item.name_en}</div>`
        }
        if (item.name_origins) {
            desc += `<div><span>名字来源:</span> ${item.name_origins}</div>`
        }
    }

    if (item.type == "artifical") {
        desc = `<div style="text-align: center; font-weight: bold; font-size: larger;">${item.name}</div>` + desc
        desc += `<div><span>质量:</span> ${item.kg} 千克 </div>`
        desc += `<div> ${item.country} ${item.year} 年</div>`
    }

    m.bindPopup("<div class='placePopupContent'>" + desc + "</div>", {
        className: "placePopup"
    })

    return m
}

// 创建一个评论的 Marker
export function MakeCommentMarker(comment) {
    // let measure = MeasureText("🗩", "bold 16px sans")
    // let iconURL = SVGText("🗩", {size: "16px", color: "#0ff"})

    let measure = {width: 16, height: 16}
    let iconURL = CommentURL
    let m = new L.marker(new L.LatLng(comment.latitude, comment.longitude), {
        icon: new L.Icon({
             iconUrl: iconURL,
             iconAnchor: [0, measure.height - 5],   // -5 是微调量
             popupAnchor: [measure.width / 2, -measure.height / 2 + 5 ],    // +7 是微调量
        }),
        opacity: 0.9,
    })

    let p = new L.popup({
        className: "commentPopup",
        autoClose: false,
        closeOnClick: false,
        autoPan: false,
        opacity: 0.5,
    })
    p.setLatLng([comment.latitude, comment.latitude])
    
    // 拼接弹框信息
    let nameHTML
    if (comment.username !== "") {
        nameHTML = `<span class="text-gray-700 font-bold">${HTMLEntities(comment.username)}</span>`
    } else {
        nameHTML = '<span class="text-gray-300">无名氏</span>'
    }
    let desc = `
    <div class="flex flex-col">
        <div class="flex flex-row gap-1 mb-1 text-gray-400">
            ${nameHTML}
            <span>留言: </span>
        </div>
        <div class="commentPopupContent">${HTMLEntities(comment.content)}</div>
        <div class="text-gray-400 mt-1">${TimeToReadable(comment.create_time)}</div>
    </div>
    `

    p.setContent(desc)
    
    m.bindPopup(p)

    return m
}


// 将地图的 getPixelsWorldBounds() 返回的像素坐标转换成投影坐标
// 传入参数应类似 {x0: 1, y0: 1, x1: 100, y1: 200}
// 返回值的格式与传入参数相同
export function PixelBoundsToProjectedBounds(xy0xy1) {
    let factor = (190.367431640625/256)*7
    let ret = {}

    Object.keys(xy0xy1).forEach(k => {
        ret[k] = xy0xy1[k] * factor
    })

    return ret
}


// 输入经纬度，给经纬度加上 NEWS，同时去除负号
export function FormatPosition(latitude, longitude) {
    let ret = ""
    if (latitude < 0) {
        ret += Math.abs(latitude) + "°S"
    } else {
        ret += Math.abs(latitude) + "°N"
    }
    ret += " "
    if (longitude < 0 ){
        ret += Math.abs(longitude) + "°W"
    } else {
        ret += Math.abs(longitude) + "°E"
    }
    return ret
}



const HTMLEntities = function(str) {
    let ret = str.replace(/./gm, function(s) {
        // return "&#" + s.charCodeAt(0) + ";";
        return (s.match(/[a-z0-9\s]+/i)) ? s : "&#" + s.charCodeAt(0) + ";";
    });

    try {
        ret = ret.replaceAll("\n", "<br />")    
    } catch (e) {
        console.error("HTMLEntities 中的 replaceAll 失败，ret 和 e 分别是", ret, e)
    }

    return ret
};

// 传入秒级的 Unix 时间戳，返回可读的格式
const TimeToReadable = function(ts) {
    const date = (new Date())
    date.setTime(ts * 1000)
    
    const year = date.getFullYear()
    const month = date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
    const day = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`
    const hours = date.getHours() >= 10 ? date.getHours() : `0${date.getHours()}`
    const min = date.getMinutes() >= 10 ? date.getMinutes() : `0${date.getMinutes()}`
    const s = date.getSeconds() >= 10 ? date.getSeconds() : `0${date.getSeconds()}`
    
    return `${year}-${month}-${day} ${hours}:${min}:${s}`
}

