/**
 * opts.color 字体颜色
 * opts.size 字体大小
 */
export const SVGText = (text, opts) => { 
    let c = Object.assign({
        size: 12,
        color: "#000",
        strokeWidth: 0,
        stroke: "",
        opacity: 0.9,
    }, opts)

    if (!isNaN(c.size)) {
        c.size = `${c.size}px`
    }


    let measure = MeasureText(text, `bold ${c.size} sans`)
    let width = Math.ceil(measure.width)
    let height = Math.ceil(measure.height)

    let shadowPx = "1"
    let contrast = "0.3"
    if (height > 22) {
        shadowPx = "0.5"
        contrast = "0.2"
    }

    let svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
    <text x="50%" y="50%" 
    font-size="${c.size}"
    font-family="sans"
    fill="${c.color}"
    style="font-weight: bold; filter: contrast(${contrast}); text-shadow: ${shadowPx}px ${shadowPx}px #000, ${shadowPx}px ${shadowPx}px #ccc"
      text-anchor="middle" dominant-baseline="middle" opacity="${c.opacity}" stroke-width="${c.strokeWidth}" stroke="${c.stroke}">${text}</text>
    </svg>
    `
    return "data:image/svg+xml;," + encodeURIComponent(svg)
}

// 测量文字宽度
// font 参数类似 "bold 12px arial"
// 返回的宽度为浮点数
export function MeasureText(text, font) {
    let c = MeasureText.canvas || (MeasureText.canvas = document.createElement("canvas"))
    let ctx = c.getContext("2d")
    if (font) {
        ctx.font = font
    }
    let measure = ctx.measureText(text)
    measure.height = measure.fontBoundingBoxAscent + measure.fontBoundingBoxDescent

    // 似乎 Firefox 没有 fontBoudingBoxAscent|Descent 属性
    if (isNaN(measure.height)) {
        measure.height = measure.actualBoundingBoxAscent + measure.actualBoundingBoxDescent
        measure.height *= 1.1
    }

    // console.log("测量结果", text, measure.width, measure.height)

    return measure
}

// export const Rocket2 = `<svg xmlns="http://www.w3.org/2000/svg" class="ov-icon" aria-hidden="true" width="14" height="14" viewBox="0 0 24 24" fill="#6cf" style="font-size: 2.88em; filter: contrast(0.1); opacity: 0.9;">
// <path fill="none" d="M0 0h24v24H0z"></path>
// <path d="M5.33 15.929A13.064 13.064 0 015 13c0-5.088 2.903-9.436 7-11.182C16.097 3.564 19 7.912 19 13c0 1.01-.114 1.991-.33 2.929l2.02 1.796a.5.5 0 01.097.63l-2.458 4.096a.5.5 0 01-.782.096l-2.254-2.254a1 1 0 00-.707-.293H9.414a1 1 0 00-.707.293l-2.254 2.254a.5.5 0 01-.782-.096l-2.458-4.095a.5.5 0 01.097-.631l2.02-1.796zM12 13a2 2 0 100-4 2 2 0 000 4z"></path>
// </svg>`
export const Rocket = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="#6cf" style="filter: contrast(0.3); opacity: 0.9;"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2.5s4.5 2.04 4.5 10.5c0 2.49-1.04 5.57-1.6 7H9.1c-.56-1.43-1.6-4.51-1.6-7C7.5 4.54 12 2.5 12 2.5zm2 8.5c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2zm-6.31 9.52c-.48-1.23-1.52-4.17-1.67-6.87L4 15v7l3.69-1.48zM20 22v-7l-2.02-1.35c-.15 2.69-1.2 5.64-1.67 6.87L20 22z"></path></svg>`
export const RocketURL = "data:image/svg+xml;," + encodeURIComponent(Rocket)

export const Comment = `<svg fill="#0ff" version="1.1" xmlns="http://www.w3.org/2000/svg" 
width="16" height="16" viewBox="0 0 28 28" style="filter: contrast(0.2); opacity: 0.9;">
<g>
<path d="M28,12.195c0,5.299-6.268,9.594-14,9.594c-0.16,0-0.316-0.013-0.475-0.017C8.972,26.01,2.638,25.363,2.638,25.363
   c3.275-0.674,4.743-2.488,5.287-4.535C3.239,19.277,0,16,0,12.195c0-5.298,6.268-9.594,14-9.594C21.732,2.603,28,6.897,28,12.195z"
   />
</g>
</svg>`
export const CommentURL = "data:image/svg+xml;," + encodeURIComponent(Comment)