import artificals from "./moon_artifical.json"
import mountains from "./moon_mountain.json"
import waters from "./moon_water.json"


var Artificals = []
Object.values(artificals).forEach(v => {
    let longlat = ConvertPosition(v.position)
    Artificals.push(Object.assign(v, {
        longitude: longlat.longitude,
        latitude: longlat.latitude,
        type: "artifical",
    }))
})

var Mountains = []
Object.values(mountains).forEach(v => {
    let longlat = ConvertPosition(v.position)
    Mountains.push(Object.assign(v, {
        longitude: longlat.longitude,
        latitude: longlat.latitude,
        type: "mountain",
    }))
})

var Waters = []
Object.values(waters).forEach(v => {
    let longlat = ConvertPosition(v.position)
    Waters.push(Object.assign(v, {
        longitude: longlat.longitude,
        latitude: longlat.latitude,
        type: "water",
    }))
})



/**
 * 输入: 12.9°S 129.1°W
 * 输出: {longitude: -129.1, latitude: -12.9}
 */
function ConvertPosition(position) {
    let ret = {longitude: 0, latitude: 0}
    if (!position) {
        return ret
    }

    Object.values(position.split(" ")).forEach(v => {
        v = v.replace("°", "")
        let value = parseFloat(v.slice(0, -1))
        switch (v.slice(-1)) {
            case 'N':
                ret.latitude = value
                break;
            case 'S':
                ret.latitude = -value
                break;
            case 'E':
                ret.longitude = value
                break;
            case 'W':
                ret.longitude = -value
                break;
        }
    })

    return ret
}



export {Artificals, Mountains, Waters}