var SimpleI18n = function(opts) {
    const messages = opts.messages

    const defaultMessages = messages[opts.locale] ?? {}
    const fallbackMessages = messages[opts.fallbackLocale] ?? {}

    return function(msg, replaces) {
        let t = msg;

        if (defaultMessages[msg] !== undefined) {
            t = defaultMessages[msg]
        } else if (fallbackMessages[msg] !== undefined) {
            t = fallbackMessages[msg]
        }

        if (replaces) {
            for (let k in replaces) {
                t = t.replaceAll("{" + k + "}", replaces[k])
            }
        }

        return t
    }
}

var GetBrowserLocale = function() {
    let defaultLocale = 'en'
    try {
        if (navigator.language.indexOf("zh") >= 0) {
            defaultLocale = 'zh'
        } else {
            defaultLocale = 'en'
        }
        console.debug("Use default language", defaultLocale)
    } catch (e) {
        console.debug("Unable to load local locale setting", e)
    }

    return defaultLocale
}

export {SimpleI18n, GetBrowserLocale}
