type FailoverResult = {
    key: string;
    url: string;
    latency_ms: number;
    response: string;
    error?: string;
};

/**
 * 给定一系列 URL，检查其是否可用
 * 传入参数为 {key1: url1, key2: url2, ...}
 * 结果为: {success: FailoverResult[], failure: FailoverResult[]}
 */
export const CheckFailover = async function(s: {}): Promise<{success: FailoverResult[]; failure: FailoverResult[]}> {
    let timeout :number = 10 * 1000;         // 最大等待时间
    let success: FailoverResult[] = []
    let failure: FailoverResult[] = []

    console.log("开始进行 Failover 检查", s)

    for (let k in s) {
        let url = s[k];
        let key = k;
        (async function() {
            try {
                const controller = new AbortController();
                const id = setTimeout(() => controller.abort(), timeout);

                let stime = (new Date()).getTime()
                let res = await fetch(url, {signal: controller.signal})
                let response = await res.text()
                let latency_ms = (new Date()).getTime() - stime

                let r: FailoverResult = {key, url, latency_ms, response}
                success.push(r)

                clearTimeout(id);
            } catch (e) {
                failure.push({key, url, latency_ms: 0, response: "", error: e.toString()})
            }
        })()
    }

    let ms = 0;
    let interval = 300;
    while(1) {
        await new Promise(r => setTimeout(r, interval))
        ms += interval
        if (success.length + failure.length >=  Object.values(s).length || ms > timeout + interval * 2) {
            break
        }
    }
    console.log("Failover 最终的结果是", {success, failure})

    
    return new Promise(r => r({success, failure}))
}


