<script setup>
// import TestTile from './TestTile.vue'
// import Search from './components/Search.vue'

// import * as L from 'leaflet'
// import 'leaflet-css'

import {CRSMoonWebMercator, ConvertToDMS, CRSMoonNorthPolar, CRSMoonSouthPolar} from "./crs.js"
import {Artificals, Waters, Mountains} from "./places/moon.js"
import {MakeMarker, MakeCommentMarker, MinDiameterOfZooms,  
    API_BASE_SS, API_BASE_KK, API_BASE_FZ} from "./common.js"
import {cache} from "./cache.ts"
import {CheckFailover} from "./misc.ts"
import {GetCraters} from "./crater.ts"

import {EnhanceA, EnhanceB, GetHistByImgs} from "./image.ts"


// 修复 Leaflet 瓦片之间的白线
// https://github.com/Leaflet/Leaflet/issues/3575#issuecomment-150544739
(function(){
    var originalInitTile = L.GridLayer.prototype._initTile
    L.GridLayer.include({
        _initTile: function (tile) {
            originalInitTile.call(this, tile);

            var tileSize = this.getTileSize();

            tile.style.width = tileSize.x + 1 + 'px';
            tile.style.height = tileSize.y + 1 + 'px';
        }
    });
})()
</script>


<script>
import { defineAsyncComponent } from 'vue'
import {OhVueIcon, addIcons} from 'oh-vue-icons'
import {BiLayersHalf, MdPanoramaphotosphereTwotone, MdRemoveredeyeOutlined, IoTriangleSharp, RiLoader4Line,
    FaWater, GiMountainCave, SiApacherocketmq, CoHipchat, GiWingedShield, GiFireRing, GiCometSpark, 
    MdImagesearch, RiImageEditFill} from "oh-vue-icons/icons";
addIcons(BiLayersHalf, MdPanoramaphotosphereTwotone, MdRemoveredeyeOutlined, IoTriangleSharp, RiLoader4Line,
FaWater, GiMountainCave, SiApacherocketmq, CoHipchat, GiCometSpark, MdImagesearch, RiImageEditFill);

import * as components from "vuetify/components"

const Search = defineAsyncComponent(() => import('./components/Search.vue'))
const TestTile = defineAsyncComponent(() => import('./TestTile.vue'))
const Intro = defineAsyncComponent(() => import('./components/Intro.vue'))

// 调试用：是否打开评论查询范围显示
const isDebugCommentRange = false
const isDebugCraterRange = false

const attributionPrefix = '图像来源: <a href="http://www.bao.ac.cn/">国家天文台</a> | 嫦娥二号全月图'

const MaxZoom = 13

var M = null;       // 中低纬地图
var MN = null;      // 北极地图
var MS = null;      // 南极地图
var Maps = {}

// 目前已经创建的正在使用的位置图层
// 更新位置图层前，先删除此图层
var ActivePlaceLayers = []  

// 目前已经创建的正在使用的评论图层
// 更新评论图层前，先删除此图层
var ActiveCommentLayers = []

// 目前已经创建的正在使用的环形山图层
// 更新环形山图层前，先删除此图层
var ActiveCraterLayers = []

// 设置 API 地址
var API_BASE = API_BASE_SS


const GetTileURL = function(map) {
    return `${API_BASE}/tile/${map}/{z}/{x}/{y}.jpg`
}


const LayerLowLat = new L.tileLayer(GetTileURL("lowlat"), {crossOrigin: "", className: "ce2-lowlat"})
const LayerNorthPole = new L.tileLayer(GetTileURL("north"), {crossOrigin: "", className: "ce2-north"})
const LayerSouthPole = new L.tileLayer(GetTileURL("south"), {crossOrigin: "", className: "ce2-south"})



export default {
    components: {
        "v-icon": OhVueIcon,
        TestTile, Intro, Search,
    },
    data() {
        let saved = this.GetSavedOptions()
        return Object.assign({
            isTileLoading: false,
            isCommentLoading: false,
            isCraterLoading: false,

            isShowIntro: false,
            isShowSearch: false,
            isShowSearchResult: true,

            // 菜单状态
            isShowMenu: false,
            isShowCopiedToClipboard: false,
            isShowContextMenu: false,

            // 图层状态
            isShowLayerWater: true,
            isShowLayerMountain: true,
            isShowLayerArtifical: true,
            isShowLayerCrater: false,
            isShowLayerComment: false,

            // 只在用户首次打开评论图层开关的时候，才自动显示评论
            // 该变量用于记录此状态，控制是否自动显示评论
            isCommentAlreadyAutoPopup: false,


            // 留言窗口相关状态
            commentContent: "",
            commentUsername: "",
            isShowAddComment: false,
            isCommentSubmitting: false,

            currentMapLayer: "",
            
            clickedLongitude: 0,
            clickedLatitude: 0,

            // 图像增强方法，目前可为 "", "A", "B"，见 image.ts 中的 enhanceX 方法说明
            enhanceFilter: "",

            APINodeName: "ss",
            APILatencyMS: "",
        }, saved)
    },

    mounted() {
        let that = this
        let params = this.getURLParams(window.location.href)
        
        this.isShowSearch = true

        window.addEventListener("hashchange", () => {
            let params = that.getURLParams()
            console.debug("URL 改变了", window.location.href, params)
            
            let map = params.map
            if (params.map == "lowlat") {
                if (!params.longitude) {
                    params.longitude = 0
                }
                if (!params.latitude) {
                    params.latitude = 0
                }
            } else if (params.map == "north") {
                if (!params.longitude) {
                    params.longitude = 0
                }
                if (!params.latitude) {
                    params.latitude = 90
                }
            } else if (params.map == "south") {
                if (!params.longitude) {
                    params.longitude = 0
                }
                if (!params.latitude) {
                    params.latitude = -90
                }
            } else {
                map = "lowlat"
            }

            that.SetMapLayer(map)
            this.ActiveMap().setView([params.latitude, params.longitude], params.zoom)
        });

        const defaultConfig = {
            zoom: params.zoom,
            minZoom: 1,
            maxZoom: MaxZoom,
            zoomControl: false,
            // center: [params.latitude, params.longitude],
        }

        // console.log("初始化 lowlat")
        M = new L.map('map_lowlat', Object.assign({
            crs: CRSMoonWebMercator,
            // maxBounds: new L.latLngBounds(L.latLng(90, 180), L.latLng(-90, -180)),
        }, defaultConfig));
        // console.log("初始化 north")
        MN = new L.map('map_north', Object.assign({
            crs: CRSMoonNorthPolar,
        }, defaultConfig))
        // console.log("初始化 south")
        MS = new L.map('map_south', Object.assign({
            crs: CRSMoonSouthPolar,
        }, defaultConfig))

        Maps = {M, MN, MS}

        // 首先设置默认位置
        
        
        MS.setView(new L.LatLng(-90, 0), 2)

        

        console.log("准备开始遍历初始化")
        Object.values(Maps).forEach(map => {
            // 添加缩放按钮
            (new L.Control.Zoom({position: 'bottomright'})).addTo(map)
            map.attributionControl.setPrefix(attributionPrefix)

            let mapName = "lowlat"
            if (map == M) {
                mapName = "lowlat"
                if (params.map == 'lowlat') {
                    M.setView(new L.LatLng(params.latitude, params.longitude), params.zoom)
                } else {
                    M.setView(new L.LatLng(0, 0), 2)
                }
            } else if (map == MN) {
                mapName = "north"
                if (params.map == "north") {
                    MN.setView(new L.LatLng(params.latitude, params.longitude), params.zoom)
                } else {
                    MN.setView(new L.LatLng(90, 0), 2)
                }
            } else if (map == MS) {
                mapName = "south"
                if (params.map == "south") {
                    MS.setView(new L.LatLng(params.latitude, params.longitude), params.zoom)
                } else {
                    MS.setView(new L.LatLng(-90, 0), 2)
                }
            }

            let that = this
            // map.on("zoomend", function (e) {
            //     let latlng = e.target.getCenter()
            //     let zoom = e.target.getZoom()
            //     let url = that.BuildURL({longitude: latlng.lng, latitude: latlng.lat, zoom, map: mapName})
            //     console.log("对比 zoomend", window.location.hash, url.slice(1), window.location.hash != url.slice(1))
            //     if (window.location.hash != url.slice(1)) {
            //         console.log("进行一次 URL 变更", url, window.location.hash)
            //         history.pushState({}, '', )
            //         that.UpdateLayers()
            //     }
            // })
            // moveend 在 zoomend 之后也会触发
            map.on("click", function(e) {
                // 关闭评论窗口（如果存在）
                that.isShowAddComment = false

                // 关闭地图说明和菜单
                that.isShowIntro = false
                that.isShowMenu = false
                that.isShowSearchResult = false


                // 禁用自动弹出评论内容，同时关闭所有评论弹窗
                that.isCommentAlreadyAutoPopup = true
                Object.values(ActiveCommentLayers).forEach(v => {
                    Object.values(v.getLayers()).forEach(w => {
                        w.closePopup()
                    })
                })
            }),
            map.on("moveend", function (e) {
                let latlng = e.target.getCenter()
                let zoom = e.target.getZoom()
                
                let url = that.BuildURL({longitude: latlng.lng, latitude: latlng.lat, zoom, map: mapName})

                if (window.location.hash != url.slice(1)) {
                    // console.log("进行一次 URL 变更", url)
                    history.pushState({}, '', url)
                    that.UpdateLayers()
                }

                that.isShowSearchResult = false

                try {
                    gtag("event", "map_move")
                } catch (e) {}
            })
            map.on("mousemove", function (e) {
                let dms = ConvertToDMS(e.latlng.lng, e.latlng.lat)
                map.attributionControl.setPrefix(dms.join('&nbsp;&nbsp;') + '| ' + that.AttributionPrefix)
            })
            map.on("contextmenu", function (e) {
                // 0. 记录点击位置
                let longitude = e.latlng.lng
                let latitude = e.latlng.lat
                while(longitude > 180) {
                    longitude -= 360;
                }
                while (longitude < -180) {
                    longitude += 360;
                }
                that.clickedLatitude = latitude
                that.clickedLongitude = longitude

                

                // 2. 先把菜单放到左上角，显示出来，然后取其正常状态下的宽高
                let dom = document.getElementById("contextMenu")
                dom.style.left = 0
                dom.style.top = 0
                that.isShowContextMenu = true

                // 3. 然后进行计算：如果显示窗口会超出界限，则调整窗口位置
                let domW = dom.clientWidth
                let domH = dom.clientHeight
                let mapSize = e.target.getSize()

                dom.style.left = e.containerPoint.x + "px"
                dom.style.top = e.containerPoint.y + "px"
                if (domW + e.containerPoint.x >= mapSize.x) {
                    dom.style.left = (e.containerPoint.x - domW) + "px"
                }
                if (domH + e.containerPoint.y > mapSize.y) {
                    dom.style.top = (e.containerPoint.y - domH) + "px"
                }  
            })
        })

        LayerLowLat.on("tileloadstart", (tile) => that.isTileLoading = true)
        LayerLowLat.on("load", (tile) => {that.isTileLoading = false; this.EnhanceImage()})
        LayerNorthPole.on("tileloadstart", (tile) => that.isTileLoading = true)
        LayerNorthPole.on("load", (tile) => {that.isTileLoading = false; this.EnhanceImage()})
        LayerSouthPole.on("tileloadstart", (tile) => that.isTileLoading = true)
        LayerSouthPole.on("load", (tile) => {that.isTileLoading = false; this.EnhanceImage()})

        console.debug("地图对象初始化完毕")


        if (!params.map) {
            params.map = 'lowlat'
        }
        this.SetMapLayer(params.map)
        this.EnsureFailover();
        setTimeout(this.SendAnalytics, 6000)

        // 关闭载入中图层
        document.getElementById("ce2-loader").style.display = "none"
    },

    methods: {
        BuildURL(state) {
            let center = this.ActiveMap().getCenter()
            let zoom = this.ActiveMap().getZoom()

            while (center.lng > 180) {
                center.lng -= 360;
            }
            while (center.lng < -180) {
                center.lng += 360;
            }
                
            let url = `/#/@${center.lat.toFixed(7)},${center.lng.toFixed(7)},${zoom}z,${this.currentMapLayer}`
            // console.log("生成了一个 URL", url)
            return url
        },
        getURLParams(url) {
            let longitude, latitude, zoom, map;

            let tokens = window.location.hash.split("/")
            
            Object.values(tokens).forEach(v => {
                if (v[0] == "@") {
                    // 解析位置参数
                    let ss = v.slice(1).split(",")
                    if (ss.length != 4) {
                        console.debug("位置参数错误，将使用默认参数")
                        latitude = longitude = 0
                        zoom = '2'
                        map = "lowlat"
                    } else {
                        latitude = ss[0] ?? '0'
                        longitude = ss[1] ?? '45'
                        zoom = ss[2] ?? '2'
                        map = ss[3] ?? 'lowlat'
                    }

                    if (map == "south" && latitude > -60) {
                        console.log("地图为南极，但纬度大于 -60，强制纬度为 -90")
                        latitude = -90
                    } else if (map == "north" && latitude < 60) {
                        console.log("地图为北极，但纬度小于 60，强制纬度为 90")
                        latitude = 90
                    }

                    zoom = zoom.replace("z", "")
                }
                
                
            })

            return {longitude, latitude, zoom, map}
        },

        // 在客户端进行 Failover 处理
        // 我们检查两台服务器，优先使用 ss，备选 kk
        // 当 ss 失效时，将 API_BASE 切换到 kk
        async EnsureFailover() {
            console.log("开始执行一次故障转移确认流程")

            const servers = {
                "ss": `${API_BASE_SS}/api/ping`,
                "kk": `${API_BASE_KK}/api/ping`,
                "fz": `${API_BASE_FZ}/api/ping`,
            }
            let res = await CheckFailover(servers)
            if (res.success.length == 0) {
                console.error("故障转移检查出错：没有可用的服务器，一分钟后重试", res)
                setTimeout(this.EnsureFailover, 60 * 1000);
                return
            }

            // 如果 ss 活着，优选 ss 作为服务器
            // 其次选择 ss
            // 最后选择 kk
            let isSSAlive = false
            let isKKAlive = false
            let isFZAlive = false
            let ss, kk, fz;
            let fastest = null
            res.success.forEach((v, k) => {
                if (v.key == "ss") {
                    isSSAlive = true
                    ss = v
                }  else if (v.key == "kk") {
                    isKKAlive = true
                    kk = v
                } else if (v.key == "fz") {
                    isFZAlive = true
                    fz = v
                }

                if (fastest == null) {
                    fastest = v
                } else if (v.latency_ms < fastest.latency_ms) {
                    fastest = v
                }
            })
            // FIXME: 此处代码需要规整
            if (isFZAlive) {
                console.log("fz 服务器正常，使用 fz 服务器")
                this.APINodeName = "fz"
                this.ChangeAPIBase(API_BASE_FZ, fz.latency_ms)
                setTimeout(this.EnsureFailover, 60 * 1000);
                return
            }
            if (isSSAlive) {
                console.log("ss 服务器正常，使用 ss 服务器")
                this.APINodeName = "ss"
                this.ChangeAPIBase(API_BASE_SS, ss.latency_ms)
                setTimeout(this.EnsureFailover, 60 * 1000);
                return
            }
            if (isKKAlive) {
                console.log("kk 服务器正常，使用 kk 服务器")
                this.APINodeName = "kk"
                this.ChangeAPIBase(API_BASE_KK, kk.latency_ms)
                setTimeout(this.EnsureFailover, 60 * 1000);
                return
            }

            console.error("无可用的服务器，故障转移失败，一分钟后重试", res)
            setTimeout(this.EnsureFailover, 60 * 1000);
        },

        ChangeAPIBase(newBase, latency) {
            if (latency) {
                this.APILatencyMS = latency
            }

            if (newBase == API_BASE) {
                return
            }

            console.log("设置新的 API_BASE 为", newBase)
            API_BASE = newBase

            console.log("更新瓦片图层地址")
            LayerLowLat.setUrl(GetTileURL("lowlat"))
            LayerNorthPole.setUrl(GetTileURL("north"))
            LayerSouthPole.setUrl(GetTileURL("south"))
        },
        

        ActiveMap() {
            if (this.currentMapLayer == "north") {
                // console.log("返回 MN")
                return MN;
            } else if (this.currentMapLayer == "south") {
                // console.log("返回 MS")
                return MS;
            } else {
                // console.log("返回 M")
                return M;
            }
        },
        ActiveMapName() {
            if (this.currentMapLayer) {
                return this.currentMapLayer
            } else {
                return "lowlat"
            }
        },

        SetMapLayer(name) {
            var target;
            var params = this.getURLParams()
            if (name == "lowlat") {
                if (!Maps.M.hasLayer(LayerLowLat)) {
                    LayerLowLat.addTo(Maps.M)
                }
                target = LayerLowLat
            } else if (name == "north") {
                if (!Maps.MN.hasLayer(LayerNorthPole)) {
                    LayerNorthPole.addTo(Maps.MN)   
                }
                target = LayerNorthPole
            } else if (name == "south") {
                if (!Maps.MS.hasLayer(LayerSouthPole)) {
                    LayerSouthPole.addTo(Maps.MS)
                }
                target = LayerSouthPole
            } else {
                console.error("指定的 layer 不存在: ", name)
                return
            }
                    
            if (this.currentMapLayer == name) {
                console.debug("MapLayer 未改变，无需切换 MapLayer")
                return
            } 

            Object.values(["lowlat", "north", "south"]).forEach(v => {
                let dom = document.getElementById(`map_${v}`)
                if (v != name) {
                    dom.style.zIndex = 100
                } else {
                    dom.style.zIndex = 101;
                }
            })


            this.currentMapLayer = name
            let state = {
                longitude: params.longitude,
                latitude: params.latitude,
                zoom: params.zoom,
                map: name,
            }
            console.log("state 是", state)
            history.pushState({}, '', this.BuildURL(state))

            this.UpdateLayers()
            this.EnhanceImage()

            gtag("event", "map_change", {event_label: name})
        },

        UpdateLayers() {
            // 1. 根据当前配置，生成图层
            let uparams = this.getURLParams()
            let zoom = parseInt(uparams.zoom)

            let places = [];
            
            let  ms = []
            if (this.isShowLayerWater) {
                places.push(...Waters)
            }
            if (this.isShowLayerMountain) {
                places.push(...Mountains)
            }
            if (this.isShowLayerArtifical) {
                places.push(...Artificals)
            }

            Object.values(places).forEach(v => {
                let priority = parseFloat(v.diameter_km)

                // 跳过没有位置信息的或无效的内容
                if (!v.position) {
                    return
                }
                // 月溪只能显示在至少 8 层级
                if (zoom < 8 && v.name.indexOf("月溪") >= 0) {
                    return
                }
                // 月谷狭长，降低其优先级
                if (v.name.indexOf("月谷") >= 0) {
                    priority /= 4
                }
                // 过滤掉在这个放大层次不能显示的内容
                if (MinDiameterOfZooms[zoom]) {
                    if (priority < MinDiameterOfZooms[zoom]) {
                        return
                    }
                }

                // 如果是极点地图，过滤掉纬度低于 0 的点
                // 对于中低纬地图，过滤掉纬度高于 0 的点
                let activeMap = this.ActiveMap()
                if (activeMap == M && (v.latitude > 89 || v.latitude < -89)) return
                if (activeMap == MN && v.latitude < 0) return
                if (activeMap == MS && v.latitude > -0) return
                
                
                ms.push(MakeMarker(v))
            })
            
            // 2. 删除现有图层
            while (ActivePlaceLayers.length > 0) {
                let l = ActivePlaceLayers.pop()
                Object.values(Maps).forEach(v => {
                    if (v.hasLayer(l)) {
                        v.removeLayer(l)
                    }
                })
            }

            // 3. 添加到地图
            var activeLayer = new L.layerGroup(ms)
            activeLayer.addTo(this.ActiveMap())
            ActivePlaceLayers.push(activeLayer)

            // Object.values([M, MN, MS]).forEach(v => {
            //     let activeLayer = new L.layerGroup(ms)
            //     activeLayer.addTo(v)
            //     ActivePlaceLayers.push(activeLayer)
            // })
            
            // let activeLayer1 = new L.layerGroup(ms)
            // let activeLayer2 = new L.layerGroup([...ms])
            // let activeLayer3 = new L.layerGroup([...ms])
            // activeLayer1.addTo(M)
            // activeLayer2.addTo(MN)


            // 4. 异步更新 Comment 和 Crater 图层
            this.UpdateCommentLayer()
            this.UpdateCraterLayer()

            // 4. 保存当前的状态
            this.SaveOptions()
            
        },

        async UpdateCommentLayer() {
            if (this.isShowLayerComment == false) {
                console.debug("用户评论图层开关未打开，不会更新用户评论")
                return
            }

            // 1. 记录当前的位置
            let beforeCenter = this.ActiveMap().getCenter()

            // 2. 向服务器发送请求
            let uparams = this.getURLParams()
            let qss = []
            // let f = new FormData()
            // let bounds = this.ActiveMap().getPixelBounds()
            // console.log("当前的 pixel bounds 是", bounds)
            // console.log("当前的 全图 pixel bounds 是", this.ActiveMap().getPixelWorldBounds())
            // console.log("当前的 PixelOrigin 是", this.ActiveMap().getPixelOrigin())
            // let resolution = Math.pow(2, MaxZoom - this.ActiveMap().getZoom())
            // bounds = {
            //     x0: bounds.min.x * resolution,
            //     y0: bounds.min.y * resolution,
            //     x1: bounds.max.x * resolution,
            //     y1: bounds.max.y * resolution,
            // }
            // console.log("转换后的 pixel bounds 是", bounds)
            // bounds = PixelBoundsToProjectedBounds(bounds)
            // // f.append("x0", Math.round(bounds.min.x))
            // // f.append("y0", Math.round(bounds.min.y))
            // // f.append("x1", Math.round(bounds.max.x))
            // // f.append("y1", Math.round(bounds.max.y))
            // // f.append("map_name", this.getURLParams().map)
            // Object.keys(bounds).forEach(k => {
            //     qss.push(encodeURIComponent(k) + "=" + encodeURIComponent(Math.round(bounds[k])))
            // })
            // qss.push(`map_name=${uparams.map}`)
            
            qss.push(`map=${uparams.map}`)
            qss.push(`longitude=${beforeCenter.lng}`)
            qss.push(`latitude=${beforeCenter.lat}`)
            qss.push(`zoom=${uparams.zoom}`)
            this.isCommentLoading = true

            let j =null
            try {
                let res = await fetch(`${API_BASE}/api/comment/list?` + qss.join("&"), {
                    method: "get",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                })
                j = await res.json()
            } catch (e) {
                this.ShowNotify("无法加载评论，网络错误: " + e.toString())
                this.isCommentLoading = false
                return
            }

            this.isCommentLoading = false
            
            if (j.code != 0) {
                this.ShowNotify("无法加载评论，服务器错误: " + j.message)
                return
            }


            // 3. 解析结果
            let ms = []
            let activeMap = this.ActiveMap()
            Object.values(j.data.comments).forEach(m => {
                // 如果是极点地图，过滤掉纬度低于 0 的点
                // 对于中低纬地图，过滤掉纬度高于 0 的点
                if (activeMap == MN && m.latitude < 0) return
                if (activeMap == MS && m.latitude > 0) return
                ms.push(MakeCommentMarker(m))
            })
            ms = ms.reverse()

            // 3.2 调试用：添加查询结果的包括区域
            if (isDebugCommentRange && j.data.bounds) {
                let b = j.data.bounds
                // ms.push(new L.polygon([
                //     [b[0].Y, b[0].X],
                //     [b[1].Y, b[1].X],
                //     [b[2].Y, b[2].X],
                //     [b[3].Y, b[3].X],
                // ]))
                ms.push(new L.polygon([
                    [b[0][1], b[0][0]],
                    [b[1][1], b[1][0]],
                    [b[2][1], b[2][0]],
                    [b[3][1], b[3][0]],
                ]))
            }
            

            // 4. 在添加之前，确认先决条件
            if (this.isShowLayerComment == false) {
                console.debug("由于用户评论图层开关已关闭，不会显示用户评论")
                return
            }

            let afterCenter = this.ActiveMap().getCenter()
            if (afterCenter.lat != beforeCenter.lat || afterCenter.lng != beforeCenter.lng) {
                console.debug("由于用户已经移动地图，本次不会显示用户评论，等待新的请求显示")
                return
            }
            

            // 5. 将结果添加到图层
            /// 5.1 删除现有评论图层
            this.RemoveCommentLayer()

            /// 5.2 添加新评论图层
            Object.values(Maps).forEach(map => {
                let l = new L.LayerGroup(ms)
                l.addTo(this.ActiveMap(map))
                ActiveCommentLayers.push(l)
            })

            // 5.3 自动打开评论
            if (this.isCommentAlreadyAutoPopup == false) {
                Object.values(ms).forEach(v => {
                    v.openPopup()
                })
            }
        },

        async UpdateCraterLayer() {
            if (this.isShowLayerCrater == false) {
                console.debug("环形山图层开关未打开，不会更新环形山数据")

                return
            }

            // 1. 记录当前的位置
            let beforeCenter = this.ActiveMap().getCenter()

            // 2. 向服务器发送请求
            let uparams = this.getURLParams()
            let qss = []
            
            qss.push(`map=${uparams.map}`)
            qss.push(`longitude=${beforeCenter.lng}`)
            qss.push(`latitude=${beforeCenter.lat}`)
            qss.push(`zoom=${uparams.zoom}`)
            // this.isCraterLoading = true
            // let j =null
            // try {
            //     let res = await fetch(`${API_BASE}/api/crater/list?` + qss.join("&"), {
            //         method: "get",
            //         headers: {
            //             "Content-Type": "application/x-www-form-urlencoded",
            //         },
            //     })
            //     j = await res.json()
            // } catch (e) {
            //     // this.ShowNotify("无法加载环形山数据，网络错误: " + e.toString())
            //     console.debug("无法加载环形山数据，网络错误", e)
            //     return
            // } finally {
            //     this.isCraterLoading = false
            //     console.log("Finally 环形山数据载入完毕了")
            // }
            
            // if (j.code != 0) {
            //     console.debug("无法加载环形山数据，网络错误", j.message)
            //     return
            // }
            let j = {
                data: await GetCraters(beforeCenter.lat, beforeCenter.lng, uparams.zoom, uparams.map)
            }
            // console.log("获得环形山数据", j.data)

            // 3. 解析结果
            let ms = []
            let activeMap = this.ActiveMap()
            Object.values(j.data.craters).forEach(m => {
                // 如果是极点地图，过滤掉纬度低于 0 的点
                // 对于中低纬地图，过滤掉纬度高于 0 的点
                if (activeMap == MN && m.latitude < 0) return
                if (activeMap == MS && m.latitude > 0) return
                m.name += "环形山"
                m.type = "crater"
                m.diameter_km = parseFloat(m.diameter_km)
                ms.push(MakeMarker(m))
            })
            ms = ms.reverse()

            // 3.2 调试用：添加查询结果的包括区域
            if (isDebugCraterRange && j.data.bounds) {
                let b = j.data.bounds
                ms.push(new L.polygon([
                    [b[0][1], b[0][0]],
                    [b[1][1], b[1][0]],
                    [b[2][1], b[2][0]],
                    [b[3][1], b[3][0]],
                ]))
            }
            

            // 4. 在添加之前，确认先决条件
            if (this.isShowLayerCrater == false) {
                console.debug("由于环形山图层开关已关闭，不会显示环形山图层")
                return
            }

            let afterCenter = this.ActiveMap().getCenter()
            if (afterCenter.lat != beforeCenter.lat || afterCenter.lng != beforeCenter.lng) {
                console.debug("由于用户已经移动地图，本次不会显示环形山数据，等待新的请求显示")
                return
            }
            

            // 5. 将结果添加到图层
            /// 5.1 删除现有评论图层
            this.RemoveCraterLayer()

            /// 5.2 添加新评论图层
            Object.values(Maps).forEach(map => {
                let l = new L.LayerGroup(ms)
                l.addTo(this.ActiveMap(map))
                ActiveCraterLayers.push(l)
            })
        },

        RemoveCommentLayer() {
            while(ActiveCommentLayers.length > 0){ 
                let l =ActiveCommentLayers.pop()
                Object.values(Maps).forEach(map => {
                    if (map.hasLayer(l)) {
                        map.removeLayer(l)
                    }
                })
            }
        },
        RemoveCraterLayer() {
            while(ActiveCraterLayers.length > 0){ 
                let l =ActiveCraterLayers.pop()
                Object.values(Maps).forEach(map => {
                    if (map.hasLayer(l)) {
                        map.removeLayer(l)
                    }
                })
            }
        },

        SaveOptions() {
            cache.set("moon_options", {
                isShowLayerArtifical: this.isShowLayerArtifical,
                isShowLayerWater: this.isShowLayerWater,
                isShowLayerMountain: this.isShowLayerMountain,
                isShowLayerCrater: this.isShowLayerCrater,
                isShowLayerComment: this.isShowLayerComment,
                // enhanceFilter: this.enhanceFilter,
            }, 86400 * 7)
        },
        GetSavedOptions() {
            let o = cache.get("moon_options")
            return o ?? {}
        },

        ShowAddComment() {
            console.log("打开评论窗口")
            // let t = new L.popup([this.clickedLatitude, this.clickedLongitude], {
            //     content: document.getElementById("addComment").cloneNode(true),
            //     className: "addComment",
            // }).openOn(this.ActiveMap())
            this.ActiveMap().panTo([this.clickedLatitude, this.clickedLongitude])

            // 1. 设置评论窗口的位置
            // 1.1 首先设置评论窗口到左上角
            let dom = document.getElementById("addComment")
            this.isShowAddComment = true
            dom.style.left = 0
            dom.style.top = 0
            

            // 1.2 然后进行计算：将窗口放在窗口正中央
            let domW = dom.clientWidth
            let domH = dom.clientHeight
            let mapSize = this.ActiveMap().getSize()
            console.log("dom WH, mapSize", domW, domH, mapSize)

            dom.style.left = (mapSize.x / 2 - domW / 2) + "px"
            dom.style.top = (mapSize.y / 2 - domH + 10) + "px"
        },

        async SubmitComment() {
            console.log("开始提交表单")

            if (this.commentContent == "") {
                return
            }

            this.isCommentSubmitting = true
            this.isLoading = true
            let that = this
            let uparams = this.getURLParams()
            try {
                let res = await fetch(`${API_BASE}/api/comment/add`, {
                    method: "post",
                    body: JSON.stringify({
                        content: that.commentContent,
                        username: that.commentUsername,
                        longitude: parseFloat(that.clickedLongitude),
                        latitude: parseFloat(that.clickedLatitude),
                        zoom: that.ActiveMap().getZoom(),
                        map_name: uparams.map,
                    })
                })
                let j = await res.json()
                if (j.code != 0) {
                    this.ShowNotify("留言失败: " + j.message)
                    throw "留言失败"
                }

                // 留言成功
                this.ShowNotify("留言成功")

                that.commentContent = ""
                that.isShowAddComment = false
                await that.UpdateCommentLayer()
                this.isShowLayerComment = true
            } catch (e) {
                console.warn(e)
            }


            console.log("完成表单提交")
            this.isCommentSubmitting = false
            this.isLoading = false
        },

        async SendAnalytics() {
            try {
                fetch(`${API_BASE}/api/analytics`, {
                    method: "post",
                    headers: {
                        'content-type': 'application/json',
                    }, 
                    body: JSON.stringify({
                        referrer: document.referrer ?? '',
                        languages: JSON.stringify(navigator.languages ?? ''),
                    }),
                })
            } catch (e) {
                console.debug("回报统计信息失败", e)
            }
        },

        // 用户点击了搜索结果
        SearchResultClicked(result) {
            console.log("用户点击了搜索结果", result)
            this.isShowSearchResult = false

            if (result.latitude > 80) {
                this.SetMapLayer("north")
            } else if (result.latitude < -80) {
                this.SetMapLayer("south")
            } else {
                this.SetMapLayer("lowlat")
            }
            this.ActiveMap().flyTo([result.latitude, result.longitude], 8)

            gtag("event", "search_result_click", {event_label: result.name})
        },

        EnhanceImage() {
            let selector = `.ce2-${this.ActiveMapName()}`

            if (this.enhanceFilter == "") { 
                console.debug("图像自动增强已关闭")
                document.querySelector(selector).style.filter = ""
                return
            }

            let imgs = document.querySelectorAll(`${selector} img`)
            let hist = null
            try {
                hist = GetHistByImgs(imgs)
            } catch (e) {
                console.warn('未能获取当前瓦片直方图，无法继续图像增强处理')
                return
            }

            let filter
            if (this.enhanceFilter == "A" ){
                console.debug("使用自动图像增强 A 方法")
                filter = EnhanceA(hist)
            } else if (this.enhanceFilter == "B") {
                console.debug("使用自动图像增强 B 方法")
                filter = EnhanceB(hist)
            }
            
            console.debug("设置自动图像增强滤镜", filter)
            let t = document.querySelector(selector)
            console.log(selector, t)
            t.style.filter = `brightness(${filter.brightness}) contrast(${filter.contrast})`
        },

        ShowNotify(msg) {
            console.debug("显示提示：", msg)

            let div = document.createElement("div")
            div.className = "notify"
            div.innerText = msg
            document.getElementById("notifies").appendChild(div)
            setTimeout(function() {
                div.remove()
            }, 3000)
        },


        async CopyDD() {
            try { 
                await navigator.clipboard.writeText(this.clickedPositionDD)
                this.isShowCopiedToClipboard = true
                setTimeout(() => {this.isShowCopiedToClipboard = false}, 3000)
            } catch (e) {
                console.log("将经纬度复制到剪贴板出错: ", e)
            }
            this.isShowContextMenu = false
        },
        async CopyDMS() {
            try {
                await navigator.clipboard.writeText(this.clickedPositionDMS)
                this.isShowCopiedToClipboard = true
                setTimeout(() => {this.isShowCopiedToClipboard = false}, 3000)
            } catch (e) {
                console.log("将经纬度复制到剪贴板出错: ", e)
            }
            this.isShowContextMenu = false
        },
    },

    computed: {
        isLoading() {
            return this.isCommentLoading || this.isTileLoading || this.isCraterLoading
        },
        isTileTest() {
            return window.location.href.indexOf('TileTest') > 0
        },
        clickedPositionDD() {
            return this.clickedLatitude.toFixed(7) + ", " + this.clickedLongitude.toFixed(7)
        },
        clickedPositionDMS() {
            let dms = ConvertToDMS(this.clickedLongitude, this.clickedLatitude)
            return `${dms[1]} ${dms[0]}`
        },
        AttributionPrefix() {
            let items = [
                '图像来源: <a href="http://www.bao.ac.cn/">国家天文台</a>'
            ]

            if (this.APILatencyMS) {
                items.push(`当前服务器: <span class="text-gray-600">${this.APINodeName}</span> 延迟: <span class="text-gray-600">${this.APILatencyMS}ms</span>`)
            }
            if (this.enhanceFilter != "") {
                items.push("已开启自动图像增强")
            }

            items.push('嫦娥二号全月图')

            return items.join(" | ")
        },
    },

    watch: {
        isShowLayerArtifical() {this.UpdateLayers()},
        isShowLayerMountain() {this.UpdateLayers()},
        isShowLayerWater() {this.UpdateLayers()},
        isShowLayerCrater(newValue, old) {
            if (newValue == true) {
                this.UpdateCraterLayer()
            } else {
                this.RemoveCraterLayer()
            }
        },
        isShowLayerComment(newValue, old) {
            this.isCommentAlreadyAutoPopup = false
            if (newValue == true) {
                this.UpdateCommentLayer()
            } else {
                this.RemoveCommentLayer()
            }
        },
        isShowAddComment(newValue, old){ 
            if (newValue == true) {
                document.getElementById("addComment").style.visibility = "visible"
            } else {
                document.getElementById("addComment").style.visibility = "hidden"
            }
        },
        isShowMenu(newValue) {
            this.isShowSearch = !newValue
        },
        enhanceFilter() {
            this.EnhanceImage()
        },
    },

}
</script>

<template>
    <div v-show="!isTileTest" @click="isShowContextMenu = false">
        <!-- 地图 -->
        <div class="map" id="map_lowlat"></div>
        <div class="map" id="map_south"></div>
        <div class="map" id="map_north"></div>
        
        <!-- 右键菜单 -->
        <div id="contextMenu" :style="{visibility: isShowContextMenu ? 'visible' : 'hidden'}">
            <div @click="CopyDD">{{clickedPositionDD}}</div>
            <div @click="CopyDMS">{{clickedPositionDMS}}</div>
            <div @click="ShowAddComment">在此留言</div>
        </div>
        <Transition>
            <div id="copiedToClipboard" v-if="isShowCopiedToClipboard">经纬度已复制到剪贴板</div>
        </Transition>

        <!-- 评论窗口 -->
        <div id="addComment" class="p-2">
            
            <form class="flex flex-col gap-2 items-center rounded" @submit.prevent="SubmitComment" @keyup.enter.ctrl="SubmitComment">
                <h1 class="font-bold">在此留言</h1>
                <div>
                    <input type="text" name="username" placeholder="名字（可不填）" v-model="commentUsername" />
                </div>
                <div>
                    <textarea name="content" placeholder="留言内容" rows="4" v-model="commentContent" />
                </div>
                <div>
                    <button type="submit" :disabled="commentContent == '' || isCommentSubmitting" :class="{'border-gray-200': commentContent == ''}">提交</button>
                </div>
            </form>
            <div class="text-center -mt-2 arrow">
                <v-icon name="io-triangle-sharp" scale="1" />
            </div>
        </div>

        <!-- 通知窗口 -->
        <div id="notifies"></div>

        <!-- 说明介绍 -->
        <Transition>
            <Component :is="Intro" v-show="isShowIntro" />
        </Transition>

        <!-- 左栏菜单 -->
        <div class="menuContainer">
            <div class="menuToggle m-4 bg-gray-100 rounded-md opacity-90 text-center">
                <img v-show="!isLoading" src="/menu_black_24dp.png" @click="isShowMenu = !isShowMenu" @keyup.enter="isShowMenu = !isShowMenu" tabindex="0" />
                <div v-show="isLoading" class="flex items-center justify-center" @click="isShowMenu = !isShowMenu">
                    <v-icon name="ri-loader-4-line" scale="1.5" animation="spin" />
                </div>
            </div>


            <Transition name="slide-left">
            <div class="menu" v-if="isShowMenu">
                <div class="flex flex-col grow h-full">
                    <hr style="margin-top: 0;" />

                    
                    <div class="flex flex-col">
                        <div class="flex items-center gap-2 subtitle">
                            <v-icon name="md-panoramaphotosphere-twotone" scale="1.5" />
                            <h1>地图</h1>
                        </div>
                        
                        <div class="mapSelector">
                            <div @click="SetMapLayer('lowlat')" @keyup.enter="SetMapLayer('lowlat')" tabindex="0" >
                                <div>
                                    <v-icon name="md-removeredeye-outlined" v-if="currentMapLayer == 'lowlat'" />
                                </div>
                                <div>
                                    中低纬
                                </div>
                            </div>
                            
                            <div @click="SetMapLayer('north')"  @keyup.enter="SetMapLayer('north')"   tabindex="0">
                                <div>
                                    <v-icon name="md-removeredeye-outlined" v-if="currentMapLayer == 'north'" />
                                </div>
                                <div>北极</div>
                            </div>
                            <div @click="SetMapLayer('south')"  @keyup.enter="SetMapLayer('south')"  tabindex="0">
                                <div>
                                    <v-icon name="md-removeredeye-outlined" v-if="currentMapLayer=='south'" />
                                </div>
                                <div>南极</div>
                            </div>
                        </div>
                    </div>
                    <hr />

                    <div class="flex flex-col">
                        <div class="flex items-center gap-2 subtitle">
                            <v-icon name="bi-layers-half" scale="1.5" />
                            <h1>图层</h1>
                        </div>

                        <div class="layerSelector">
                            <div @click="isShowLayerCrater = !isShowLayerCrater">
                                <input type="checkbox" v-model="isShowLayerCrater" />
                                <v-icon name="gi-comet-spark" />
                                <span> 环形山</span>
                            </div>
                            <div @click="isShowLayerWater = !isShowLayerWater">
                                <input type="checkbox" v-model="isShowLayerWater" />
                                <v-icon name="fa-water" />
                                <span>“水体”</span>
                            </div>
                            <div @click="isShowLayerMountain = !isShowLayerMountain">
                                <input type="checkbox" v-model="isShowLayerMountain" />
                                <v-icon name="gi-mountain-cave" />
                                <span> 山峰峡谷</span>
                            </div>
                            <div @click="isShowLayerArtifical = !isShowLayerArtifical">
                                <input type="checkbox" v-model="isShowLayerArtifical" />
                                <v-icon name="si-apacherocketmq" />
                                <span> 人造物体位置</span>
                            </div>
                            <div @click="isShowLayerComment = !isShowLayerComment" v-tooltip.right="'在地图上点鼠标右键即可发表留言'">
                                <input type="checkbox" v-model="isShowLayerComment" />
                                <v-icon name="co-hipchat" />
                                <span>
                                    用户留言
                                </span>
                            </div>
                        </div>
                    </div>
                    
                    <hr />

                    <div class="flex flex-col">
                        <div class="flex items-center gap-2 subtitle">
                            <v-icon name="ri-image-edit-fill" scale="1.5" />
                            <h1 v-tooltip.right="'使用自动图像增强可以在特定区域更清晰地查看细节'">自动图像增强</h1>
                        </div>


                        <div class="layerSelector">
                            <div @click="enhanceFilter = ''">
                                <input type="radio" value="" name="enhanceFilter" v-model="enhanceFilter" />
                                <!-- <v-icon name="gi-comet-spark" /> -->
                                <span> 关闭</span>
                                <!-- <v-tooltip activator="parent" location="end">Tooltip</v-tooltip> -->
                            </div>
                            <div @click="enhanceFilter = 'A'" v-tooltip.right="'适合查看环形山、溅射区等亮度较高的区域'">
                                <input type="radio" value="A" name="enhanceFilter" v-model="enhanceFilter" />
                                <!-- <v-icon name="fa-water" /> -->
                                <span> 自动拉伸对比度</span>
                            </div>
                            <div @click="enhanceFilter = 'B'" v-tooltip.right="'适合查看月海等亮度较低的区域'">
                                <input type="radio" value="B" name="enhanceFilter" v-model="enhanceFilter" />
                                <!-- <v-icon name="gi-mountain-cave" /> -->
                                <span> 自动提高亮度</span>
                            </div>
                        </div>
                    </div>
                    
                    <hr />
                    <div class="flex flex-col grow justify-end mb-2 opacity-50">
                        <div class="cursor-pointer text-center" @click="isShowIntro = !isShowIntro">地图说明 &amp; 联系方式</div>
                    </div>


                </div>
            </div>
            </Transition>


        <Transition>
            <Component :is="Search" v-show="isShowSearch" :isShowResult="isShowSearchResult" @showResult="isShowSearchResult = true" @resultClick="SearchResultClicked" />
        </Transition>
            
        </div>
    </div>

    <div v-if="isTileTest">
        <Component :is="TestTile" />
    </div>

    

</template>

<style scoped>
.menuToggle {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
}
.menuToggle>div {
    width: 40px;
    height: 40px;
}
.menuContainer img {
    width: 40px;
    height: 40px;
    padding: 6px;
}
.menu {
    position: absolute;
    top: 0;
    left: 0;
    z-index:999;
    height: 100%;
    min-width: 200px;
    padding: 70px 1em 0 1em;
    backdrop-filter: blur(5px) contrast(0.3) brightness(1.8);
    background-color: #fffffff0;
    /* box-shadow: 0 0 10px 6px rgba(0, 0, 0, .5); */
}
.menu h1 {
    font-weight: bold;
}
.menu hr {
    margin: 1em 0 0 0;
}
.menu div>div>div {
    margin-left: 0.5em;
    margin-top: 0.5em;
}

.mapSelector>div {
    display: grid;
    grid-template-columns: 1.5em auto;
    cursor: pointer;
}
.mapSelector {
    margin-left: 0 !important;
}
.layerSelector span {
    cursor: default;
}
.layerSelector input {
    margin-right: 0.5em;
    scale: 1.2;
}
.layerSelector input:focus {
    outline:auto;
}
.layerSelector>div {
    width: fit-content;
}

.map {
    height: 100%;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    /* 所有的 map 层都位于 100，需要显示的层位于 101 */
    z-index: 100;
}

#contextMenu {
    position: absolute;
    left: 50vw;
    top: 10vh;
    z-index: 200;
    background-color: #fffe;
    border-radius: 5px;
    line-height: 2em;
    padding: 0.2em 0;
}
#contextMenu div {
    padding: 0.2em 0.5em;
    cursor: default;
}
#contextMenu div:hover {
    background-color: #ddd;
}

#copiedToClipboard {
    background-color: #444;
    color: white;
    text-align: center;
    padding: 1em;
    position: absolute;
    top: 0;
    left: 40vw;
    width: 20vw;
    min-width: 15em;
    z-index: 300;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.slide-left-enter-active, .slide-left-leave-active {
    transition: all 0.4s ease;
}
.slide-left-enter-from, .slide-left-leave-to {
    transform:translateX(-100%);
}

#loading {
    /* border: 1px solid red; */
    margin: 0;
    padding: 0;
    z-index: 500;
    position: absolute;
    left: 0;
    bottom: 1em;
    width: 1em;
    height: 1em;
}

</style>

