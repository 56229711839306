import L from 'leaflet'
import 'proj4leaflet'

const MoonR = 1737400

var ProjectionMoonMercator = L.extend({}, L.Projection.SphericalMercator, {
    R: MoonR ,
    R_MINOR: MoonR ,
    bounds: new L.Bounds([-5458203, -5458203], [5458203, 5458203]),
})

export const CRSMoonWebMercator = L.extend({}, L.CRS.Earth, {
    infinite: false,
    wrapLng: [-180, 180],
    projection: ProjectionMoonMercator,
    transformation: (function() {
        const scale = 0.5 / (Math.PI * ProjectionMoonMercator.R);
        let ret = L.transformation(scale, 0.5, -scale, 0.5);
        // console.log("Transformation 是", ret)
        return ret
    }())
})


function ConvertDDToDMS(D, isLng) {
    return {
      dir: (D < 0) ? (isLng ? "W" : "S") : isLng ? "E" : "N",
      deg: 0 | (D < 0 ? (D = -D) : D),
      min: 0 | (((D += 1e-9) % 1) * 60),
      sec: (0 | (((D * 60) % 1) * 6000)) / 100,
    };
}

function ConvertToDMS(lng, lat) {
    while (lng > 180) {
        lng -= 360
    }
    while (lng < -180) {
        lng += 360
    }
    let dlng = ConvertDDToDMS(lng, true)
    let dlat = ConvertDDToDMS(lat, false)

    dlng.sec = dlng.sec.toFixed(2).padStart(5, '0')
    dlat.sec = dlat.sec.toFixed(2).padStart(5, '0')


    for (let i in dlng) {
        if (isNaN(dlng[i])) continue;
        dlng[i] = dlng[i].toString().padStart(2, '0')
    }
    for (let i in dlat) {
        if (isNaN(dlat[i])) continue;
        dlat[i] = dlat[i].toString().padStart(2, '0')
    }
    
    return [
        `${dlng.deg.toString().padStart(2, '0')}°${dlng.min}'${dlng.sec}''${dlng.dir}`,
        `${dlat.deg}°${dlat.min}'${dlat.sec}''${dlat.dir}`,,
    ]
}



// 定义月球球极投影系统
const MAX_ZOOM = 13;
const TILE_SIZE = 256;

// const extent = Math.sqrt(2) * MoonR;
const extent = 992800 * 7 / 2
const resolutions = Array(MAX_ZOOM + 1) 
  .fill() 
  .map((_, i) => extent / TILE_SIZE / Math.pow(2, i - 1));
  
export const CRSMoonNorthPolar = new L.Proj.CRS(
    "IAU2000:30118",
    "+proj=stere +lat_0=90 +lon_0=0 +x_0=0 +a=1737400 +b=1737400 +y_0=0 +units=m +no_defs",
    { 
        origin: [-extent, extent ], 
        bounds: L.bounds(
            L.point(-extent, extent), 
            L.point(extent , -extent)
        ), 
        resolutions: resolutions 
    }
);


export const CRSMoonSouthPolar = new L.Proj.CRS(
    "IAU2000:30120",
    // "+proj=laea +lat_0=-90 +lon_0=0 +k=1 +x_0=0 +y_0=0 +a=1737400 +b=1737400 +units=m +no_defs",
    "+proj=stere +lat_0=-90 +lon_0=0 +x_0=0 +y_0=0 +a=1737400 +b=1737400 +units=m",
    { 
        origin: [-extent  , extent ],     // TileOrigin 的意思？似乎是 (0, 0) 瓦片左上角在投影平面上的坐标（米）
        bounds: L.bounds(
            L.point(-extent , extent ), 
            L.point(extent, -extent)
        ), 
        resolutions: resolutions 
    }
);


export {ProjectionMoonMercator, ConvertToDMS}