interface Filter {
    brightness: number,
    contrast: number,
}

export function GetHistByImgs(imgs) :number[] {
    let hist = new Array(256).fill(0)


    let sum = 0
    let count = 0
    let canvas = document.createElement("canvas")
    let ctx = canvas.getContext("2d")
    if (!ctx) {
        throw new Error("无法获取 canvas 对象，无法进行图像增强")
    }

    let stime = (new Date()).getTime()

    
    for (let k = 0; k < imgs.length; k++) {
        let img = imgs[k]
        
        canvas.width = img.naturalWidth
        canvas.height = img.naturalHeight
        
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
        let imgData = ctx.getImageData(0, 0, canvas.width, canvas.height).data

        let dataLength = imgData.length / 4
        for (let n = 0; n < dataLength; n++) {
            let color = imgData[n * 4] ;
            sum += color
            hist[color]++
        }

        count += dataLength
    }

    let etime = (new Date()).getTime()
    // console.debug("取色耗时毫秒", etime - stime)
    // console.debug("结果，图块数、总数、像素数、均值", imgs.length, sum, count, sum / count)
    // console.debug("直方图是", hist)

    return hist
}

// 使用 CSS 亮度调节，将直方图两边的边距设置为相同距离，然后应用对比度拉伸
export function EnhanceA(hist: number[]) {
    /**
    * 计算自动拉伸直方图
    * 1. 应用 brightness 滤镜，将直方图拉伸到左右边距都相等
    *   设直方图左边距为 a，右边距为 b，拉伸后边距为 a' 和 b'，我们要求 a'==b'，则有：
    *       a * p == 255 - b * p
    *   其中 b 为拉伸系数（即 CSS 中的 brightness(b)）
    *   可求得：
    *       p = 255 / (a + b)
    *   拉伸后的左边距为 
    *       a' = a * p
    * 
    * 2. 应用对比度滤镜。（猜测：CSS 的 contrast 滤镜与 PhotoShop 的对比度实现类似：定义中央颜色为 127，然后向两边按比例拉伸直方图）
    *   需要将边距拉伸到 0，于是：
    *       q = 1 + 127 / (127 - a')
    *   其中 q 即 CSS 滤镜中的 contrast(q) 参数
    */

    // 1. 计算左右边距
    // 考虑到图像中可能有大量的 0 和 255 颜色，我们忽略这两种颜色
    
    let count = hist.reduce((v, r) => v + r)

    let leftPad = 0
    let rightPad = 0
    let discardRatio = 0.001    // 边距以外的范围最多包含的像素点比例
    let shouldDiscard = discardRatio * count
    let discardCount

    // 计算左边距和右边距
    discardCount = 0
    for (let i = 1; i < 254 / 2; i++) {
        discardCount += hist[i]
        // console.log("计算左边距", i, discardCount, shouldDiscard)
        if (discardCount >= shouldDiscard) {
            break
        }
        leftPad = i
    }
    discardCount = 0
    for (let i = 254; i > 254 / 2; i--) {
        discardCount += hist[i]
        if (discardCount >= shouldDiscard) {
            break
        }
        rightPad = 255 - i
    }

    console.log ("计算得到左右边距分别为", leftPad, rightPad)

    let p = 255 / (leftPad + (255 - rightPad))
    let q = 127 / (127 - leftPad * p)
    

    console.log("计算得到亮度和对比度系数为", p, q)


    p = Math.max(0.25, Math.min(p, 3))
    q = Math.max(0.25, Math.min(q, 3))
    
    return {brightness: p, contrast: q}

    
}

// 使用 CSS 亮图调节，将像素占比最多的颜色移动到直方图 0.38 处，然后视情况应用对比度拉伸
export function EnhanceB(hist: number[]) {
    /**
     * 1. 使用 10 个像素宽度的窗口，计算直方图最高点
     * 2. 应用 CSS 亮度滤镜，将此点放置到 127 位置
     * 3. 重新计算直方图，然后计算左右边距，最后依此应用对比度滤镜，拉伸直方图
     */
    
    // 1. 计算直方图最高点
    let window = 10
    let peak = 0
    let peakColor = 127
    for (let i = window - 1; i < 256 - window; i++) {
        let sum = hist.slice(i, i + window).reduce((a, b) => a + b)
        if (sum > peak) {
            peakColor = i
            peak = sum
        }
    }

    console.debug("直方图最高点颜色和窗口大小为", peakColor, window)

    // 2. 应用 CSS 亮度滤镜
    let p = 0.38*256 / peakColor
    

    // 3.1 重新计算直方图
    let newHist = new Array(256).fill(0)
    let pixelCount = 0
    hist.map((v, k) => {
         pixelCount += v

         let newColor = Math.round(k * p)
         if (newColor < 0) {
            newHist[0] += v
          } else if (newColor > 255) {
            newHist[255] += v
         } else {
            newHist[newColor] = v
         }
    })
    
    // 3.2 计算左右边距
    let discardRatio = 0.001
    let leftPad = 0
    let rightPad = 0
    let shouldDiscard = discardRatio * pixelCount
    let discardCount = 0

    discardCount = 0
    for (let i = 1; i < 127; i++) {
        discardCount += newHist[i]
        console.log("计算左边距", discardCount, shouldDiscard)
        if (discardCount > shouldDiscard) {
            break
        } else {
            leftPad = i
        }
    }
    discardCount = 0
    for (let i = 254; i > 127; i--) {
        discardCount += newHist[i]
        if (discardCount > shouldDiscard) {
            break
        } else {
            rightPad = 255 - i
        }
    }

    console.debug("计算得到新直方图上的左右边距为 ", leftPad, rightPad)

    console.log("旧 和 新 直方图是", hist, newHist)

    let q = 127 / (127 - Math.min(leftPad, rightPad))

    console.debug("计算得到亮度和对比度是", p, q)

    p = Math.max(0.25, Math.min(p, 3))
    q = Math.max(0.25, Math.min(q, 3))


    return {brightness: p, contrast: q}

}