import { createApp } from 'vue'
import { GetBrowserLocale, SimpleI18n } from "./simplei18n.js"
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import './style.css'
import App from './App.vue'

import messages from "../messages.json"

const app = createApp(App)
app.config.globalProperties.__ = SimpleI18n({
    // locale: 'en',
    locale: GetBrowserLocale(),
    fallbackLocal: 'zh',
    messages,
})

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: "https://683c136a430b4893bd1a0bb4952e99c9@o4504291785441280.ingest.sentry.io/4504670360633344",
    integrations: [
      new BrowserTracing({
        // routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["ce2.greensea.org", /^\//],
      }),
      new Sentry.Replay(),
    ],
    // 这个是错误的 smapleRate？
    sampleRate: 0.01,
    
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,
    // environment: import.meta.env.PROD ? "production" : "development",

    replaysSessionSampleRate: 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  
    logErrors: true,

    whitelistUrls: ["*.greensea.org"],

    denyUrls: [
      // Google Adsense
      /pagead\/js/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ],

  });
}


import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
FloatingVue.options.distance = 10
FloatingVue.options.themes.tooltip.delay.show = 0
FloatingVue.options.themes.tooltip.delay.hide = 100
app.use(FloatingVue)

app.mount('#app')
